import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAbout, updateAbout } from "../../state/actions/aboutActions";

const AboutUpdate = () => {
  const [about, setAbout] = useState({
    title: "",
    title_ru: "",
    link: "",
    text: "",
    text_ru: "",
  });

  const data = useSelector((state) => state.about);
  const dispatch = useDispatch();
  useEffect(() => {
    const geto = () => {
      return new Promise((resolve, reject) => {
        resolve(dispatch(getAbout()));
      });
    };
    geto().then(() => setAbout(data.data[0]));
  }, []);

  console.log(about,'shokolad')

  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">Yenilə</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <p>Başlıq</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={about.title}
                          onChange={(e) => {
                            setAbout({ ...about, title: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Başlıq rus</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={about.title_ru}
                          onChange={(e) => {
                            setAbout({ ...about, title_ru: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Link</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={about.link}
                          onChange={(e) => {
                            setAbout({ ...about, link: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Text</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={about.text}
                          onChange={(e) => {
                            setAbout({ ...about, text: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Text Ru</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={about.text_ru}
                          onChange={(e) => {
                            setAbout({ ...about, text_ru: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <div className="text-center">
                        {about.link && about.title ? (
                          <button
                            onClick={() => {
                              dispatch(updateAbout(about.id, about));
                            }}
                            type="button"
                            className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                          >
                            Əlavə et
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutUpdate;
