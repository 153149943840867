import Table from "../Tables/Table";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../state/actions/userActions";
import Nav from "./Nav";



const Main = () => {
  const data = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <>
     <Nav/>
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
    
      <Table users={data} />
    </main>
    </>
   
  );
};

export default Main;
