import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createVideo } from "../../state/actions/videoActions";
import { useNavigate } from "react-router-dom";
const VideoCreate = () => {
  const [video, setVideo] = useState({
    title: "",
    link: "",
  });
    const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlebutton = () => {
    dispatch(createVideo(video));
     navigate("/");
  };
  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">Yeni video elave et</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <p>Başlıq</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={video.title}
                          onChange={(e) => {
                            setVideo({ ...video, title: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Başlıq rus</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={video.title_ru}
                          onChange={(e) => {
                            setVideo({ ...video, title_ru: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Link</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={video.link}
                          onChange={(e) => {
                            setVideo({ ...video, link: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <div className="text-center">
                        {video.link && video.title ? (
                          <button
                            onClick={handlebutton}
                            type="button"
                            className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                          >
                            Əlavə et
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default VideoCreate;
