import React from "react";

import { useDispatch } from "react-redux";
import {
  deleteOrder,
  updateOrderStatus,
} from "../../state/actions/orderActions";

const OrderTable = ({ orders }) => {
  const { data } = orders;
  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(deleteOrder(id));
  };

  console.log(data)
  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">Sifarişlər</h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase  opacity-7">
                        SİFARİŞ GÜNÜ
                      </th>
                      <th className="text-uppercase text-secondary  opacity-7">
                        XİDMƏTİN ADI
                      </th>
                      {/* <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Xidmetin adi rusca
                      </th> */}
                      <th className="text-uppercase text-secondary  opacity-7 ps-2 dd">
                        XİDMƏTİN ÖLÇÜ VAHİDİ
                      </th>
                      <th className="text-center text-uppercase  opacity-7">
                        QİYMƏT
                      </th>

                      <th className="text-secondary opacity-7 dd">İCRA TARİXİ</th>
                      <th className="text-secondary opacity-7  ">AD</th>
                      <th className="text-secondary opacity-7 ">TELEFON</th>
                      <th className="text-secondary opacity-7 dd">ÜNVAN</th>
                      <th className="text-secondary opacity-7 dd">STATUS</th>
                      <th className="text-secondary opacity-7 dd">İSTİFADƏÇİ</th>
                      <th className="text-secondary opacity-7">ALƏTLƏR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.error ? (
                      <>Boshdur</>
                    ) : data.length > 0 ? (
                      data.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item?.address?.split("--")[1]}
                              </p>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.serviceName}
                              </p>
                            </td>
                            {/* <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.serviceName_ru}
                              </p>
                            </td> */}
                            <td className="align-middle text-center text-sm dd">
                              <span
                                className={` badge badge-sm bg-gradient-${
                                  item.serviceArea ? "success" : "secondary"
                                } `}
                              >
                                {item.serviceArea
                                  ? item.serviceArea + " kv"
                                  : item.serviceCount + " eded"}
                              </span>
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item?.price.toFixed(2) + " AZN"}
                            </td>
                            <td className="align-middle text-center text-sm dd">
                              {item?.orderDate}
                            </td>
                            <td className="align-middle text-center text-sm ">
                              {item?.address?.split("--")[0]}
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item.phone}
                            </td>
                            <td className="align-middle text-center text-sm dd">
                              {item?.address?.split("--")[2]}
                            </td>
                            <td className="align-middle text-center text-sm dd">
                              <span
                                className={` badge badge-sm bg-gradient-${
                                  item.status ? "success" : "secondary"
                                } `}
                              >
                                {item.status ? "tamamlanıb" : "gözləyir"}
                              </span>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0 dd">
                                {item?.userId==="undefined"?"qonaq":"istifadəçi"}
                              </p>
                            </td>

                            <td className="align-middle dd">
                              <button
                                disabled={item.status}
                                type="button"
                                className="btn btn-success"
                                style={{ marginRight: 3 }}
                                onClick={() => {
                                  dispatch(
                                    updateOrderStatus(item.id, { status: true })
                                  );
                                }}
                              >
                                {item.status ? "bitib" : "statusu dəyiş"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                Del
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTable;
