import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMainServices } from "../../state/actions/mainServiceActions";
import MainServiceTable from "./MainServiceTable";

const MainService = () => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.mainService);

  useEffect(() => {
    dispatch(getMainServices());
  }, []);

  // console.log(mainservices.data)
  if (loading) {
    return (
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <h1>Sehife yuklenir</h1>
      </main>
    );
  }
  if (error) {
    return (
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <div className="col-md-12 text-center"></div>

        <h1>xeta bash verdi</h1>

        {/* <Table users={data} /> */}
      </main>
    );
  }

  return (
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div className="col-md-12 text-center">
        {admin.isadmin || adminlocal ? (
          <Link
            to="/mainservicecreate"
            className="btn btn-lg bg-gradient-primary btn-lg w-25 offset-24 mt-4 mb-0"
          >
            yeni xidmət əlavə et
          </Link>
        ) : (
          ""
        )}
      </div>

      {/* <Table users={data} /> */}
      <MainServiceTable data={data} />
    </main>
  );
};

export default MainService;
