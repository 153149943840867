import axios from "axios";
import {
  WHATSAPP_ERROR,
  WHATSAPP_LOAD,
  WHATSAPP_SUCCESS,
  WHATSAPP_UPDATE,
} from "../action-types/whatsappActionTypes";

// const link = `http://localhost:4000/whatsapp`;
const link = `https://temizxali.herokuapp.com/whatsapp`;

// get whats app

export const getWhatsapp = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: WHATSAPP_LOAD,
      });

      const { data } = await axios.get(link);

      dispatch({
        type: WHATSAPP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WHATSAPP_ERROR,
        payload: error,
      });
    }
  };
};
// update whatsapp

export const updateWhatsapp = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: WHATSAPP_LOAD,
    });
    try {
      const { data } = await axios.put(link, obj);

      dispatch({
        type: WHATSAPP_UPDATE,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WHATSAPP_ERROR,
        payload: error,
      });
    }
  };
};
