import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getWhatsapp,
  updateWhatsapp,
} from "../../state/actions/whatsappActions";

const WhatsappUpdate = () => {
  let navigate = useNavigate();
  const [about, setAbout] = useState({
    phone_number: "",
  });

  const data = useSelector((state) => state.whatsapp);
  const dispatch = useDispatch();
  useEffect(() => {
    const geto = () => {
      return new Promise((resolve, reject) => {
        resolve(dispatch(getWhatsapp()));
      });
    };
    geto().then(() => setAbout(data.data[0]));
  }, []);

  // console.log(about);

  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">Yenilə</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <p>Başlıq</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={about.phone_number}
                          onChange={(e) => {
                            setAbout({
                              ...about,
                              phone_number: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <div className="text-center">
                        {about.phone_number ? (
                          <button
                            onClick={() => {
                              dispatch(updateWhatsapp(about));
                              navigate(`/whatsapp`);
                            }}
                            type="button"
                            className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                          >
                            Əlavə et
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WhatsappUpdate;
