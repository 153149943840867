import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UpdateServices } from "../../state/actions/xidmetActions";

const XidmetUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log("bura  xidemt update icerisidir", location.state);
  // {id: 42, name: 'Qab yuma', isArea: 0, price: 2, name_ru: 'Moyka'}
  const [xidmet, setxidmet] = useState({
    name: location.state.name || "",
    name_ru: location.state.name_ru || "",
    isArea: location.state.isArea || 0,
    price: location.state.price || 0,
  });
  const dispatch = useDispatch();
  const handlebutton = () => {
    dispatch(UpdateServices(location.state.id, xidmet));
    navigate("/");
  };
  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">Yeni xidmet elave et</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <p>Xidmetin adı</p>
                      <div className="input-group input-group-outline mb-3">
                        <input
                          value={xidmet.name}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, name: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Xidmetin adı rus </p>
                      <div className="input-group input-group-outline mb-3">
                        <input
                          value={xidmet.name_ru}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, name_ru: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <p>Xidmetin qiymeti</p>
                      <div className="input-group input-group-outline mb-3">
                        <input
                          value={xidmet.price}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, price: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="form-check form-check-info text-start ps-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={xidmet.isArea}
                          id="flexCheckDefault"
                          onChange={(e) =>
                            setxidmet({ ...xidmet, isArea: e.target.checked })
                          }
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          sahə
                        </label>
                      </div>

                      <div className="text-center">
                        {xidmet.name ? (
                          <button
                            onClick={handlebutton}
                            type="button"
                            className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                          >
                            Əlavə et
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default XidmetUpdate;
