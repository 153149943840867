import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import XidmetTable from "../Tables/XidmetTable";
import { Link } from "react-router-dom";
import { getXidmetler } from "../../state/actions/xidmetActions";
import { useState } from "react";

const Xidmet = () => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );

  const data = useSelector((state) => state.xidmet);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getXidmetler());
  }, [dispatch]);

  return (
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div className="col-md-12 text-center">
        {admin.isadmin || adminlocal ? (
          <Link
            to="/xidmetcreate"
            className="btn btn-lg bg-gradient-primary btn-lg w-25 offset-24 mt-4 mb-0"
          >
            yeni xidmət əlavə et
          </Link>
        ) : (
          ""
        )}
      </div>

      {/* <Table users={data} /> */}
      <XidmetTable xidmetler={data} />
    </main>
  );
};

export default Xidmet;
