import {
  XIDMET_CREATE_SUCCESS,
  XIDMET_DELETE_SUCCESS,
  XIDMET_ERROR,
  XIDMET_LOAD,
  XIDMET_SUCCESS,
} from "../action-types/xidemtActionTypes";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const xidmetReducers = (state = initialState, action) => {
  switch (action.type) {
    case XIDMET_LOAD:
      return {
        ...state,
        loading: true,
      };
    case XIDMET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case XIDMET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case XIDMET_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case XIDMET_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default xidmetReducers;
