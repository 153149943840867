import {
  MAINSERVICE_CREATE,
  MAINSERVICE_DELETE,
  MAINSERVICE_ERROR,
  MAINSERVICE_LOAD,
  MAINSERVICE_SUCCESS,
  MAINSERVICE_UPDATE,
} from "../action-types/mainserviceActions";

const intialState = {
  loading: false,
  data: [],
  error: "",
};

export const mainServiceReducers = (state = intialState, action) => {
  switch (action.type) {
    case MAINSERVICE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case MAINSERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case MAINSERVICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case MAINSERVICE_CREATE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case MAINSERVICE_DELETE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case MAINSERVICE_UPDATE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};
