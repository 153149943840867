

export const MAINSERVICE_LOAD = "MAINSERVICE_LOAD"

export const MAINSERVICE_ERROR = "MAINSERVICE_ERROR"

export const MAINSERVICE_SUCCESS = "MAINSERVICE_SUCCESS"

export const MAINSERVICE_CREATE = "MAINSERVICE_CREATE"

export const MAINSERVICE_DELETE = "MAINSERVICE_DELETE"
export const MAINSERVICE_UPDATE = "MAINSERVICE_UPDATE"