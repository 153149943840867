import {
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_ERROR,
  CAMPAIGN_LOAD,
  CAMPAIGN_SUCCESS,
} from "../action-types/campaignActionTypes";

const initialState = {
  data: [],
  error: "",
  loading: false,
};

export const campaignReducers = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
      };
    case CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case CAMPAIGN_DELETE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};
