import {
  XIDMET_CREATE_SUCCESS,
  XIDMET_DELETE_SUCCESS,
  XIDMET_ERROR,
  XIDMET_LOAD,
  XIDMET_SUCCESS,
} from "../action-types/xidemtActionTypes";

import axios from "axios";

// const link = `http://localhost:4000/services`;
const link = `https://temizxali.herokuapp.com/services`;

export const getXidmetler = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: XIDMET_LOAD,
      });

      const { data } = await axios.get(link);

      dispatch({
        type: XIDMET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: XIDMET_ERROR,
        payload: "erro",
      });
    }
  };
};
export const CreateService = (obj) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(link, obj);

      dispatch({
        type: XIDMET_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: XIDMET_ERROR,
        payload: "erro",
      });
    }
  };
};

export const DeleteService = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(link + `/delete/${id}`);

      dispatch({
        type: XIDMET_DELETE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
// http://localhost:4000/services/update/38

export const UpdateServices = (id, obj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: XIDMET_LOAD,
      });
      const { data } = await axios.put(link + `/update/${id}`, obj);

      dispatch({
        type: XIDMET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: XIDMET_ERROR,
        payload: "xeta bash verdi",
      });
    }
  };
};
