import {
  VIDEO_CREATE,
  VIDEO_DELETE,
  VIDEO_ERROR,
  VIDEO_LOAD,
  VIDEO_SUCCESS,
} from "../action-types/videoActionTypes";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const videoReducers = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_LOAD:
      return {
        ...state,
        loading: true,
      };
    case VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case VIDEO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case VIDEO_CREATE:
      return {
        ...state,
        data: action.payload,
      };
    case VIDEO_DELETE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
