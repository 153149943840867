import React from "react";

const AboutTable = ({ about }) => {
  const { data } = about;

  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">Haqqımızda</h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        başlıq
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        başlıq rus
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        link
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        text
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        text rus
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.error ? (
                      <>Boshdur</>
                    ) : data?.length > 0 ? (
                      data.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.title}
                              </p>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.title_ru}
                              </p>
                            </td>

                            <td className="align-middle text-center text-sm">
                              {item.link}
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item.text}
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item.text_ru}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTable;
