import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { getContact } from "../../state/actions/contactActions";
import ContactTable from "./ContactTable";

const Contact = () => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );

  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts);

  useEffect(() => {
    dispatch(getContact());
  }, [dispatch]);

  // console.log("bura contact hissesidir",contacts)

  return (
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div class="col-md-12 text-center">
        {admin.isadmin || adminlocal ? (
          <Link
            to="/contactupdate"
            className="btn btn-lg bg-gradient-primary btn-lg w-25 offset-24 mt-4 mb-0"
          >
            Contactı yenilə
          </Link>
        ) : (
          ""
        )}
      </div>
      <ContactTable contact={contacts} />
    </main>
  );
};

export default Contact;
