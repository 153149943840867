import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteMainImage } from "../../state/actions/mainImageActions";

const MainImageTable = ({ data }) => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(deleteMainImage(id));
    navigate("/");
  };

  console.log(data,"datadir")
  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">
                  Kapmaniyalar
                </h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        id
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        image
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        tool
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item) => {
                      return (
                        <tr>
                          <td>
                            <p className="text-xs font-weight-bold mb-0">
                              {item.id}
                            </p>
                          </td>
                          <td>
                            <img
                              src={`${item.imageLink}`}
                              style={{ width: 70 }}
                              alt="imglink"
                            />
                          </td>

                          {admin.isadmin || adminlocal ? (
                            <td className="align-middle">
                              {/* The button below is  delete and tested it worked but logic is to make it hide if neede open and take rest */}
                              {/* <button
                              type="button"
                              className="btn btn-danger"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => {
                                handleDelete(item.id);
                              }}
                            >
                              Del
                            </button> */}

                              <button
                                type="button"
                                className="btn btn-success"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => {
                                  navigate("/mainimageupdate", {
                                    state: item.id,
                                  });
                                }}
                              >
                                Update
                              </button>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainImageTable;
