import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { login } from "../../state/actions/authActions";

const Login = () => {
  const auth = useSelector((state) => state.auth);

  // console.log('bura login pagedir',auth)

  const dispatch = useDispatch();

  // console.log('bura',auth)
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const handleLogin = () => {
    dispatch(login(user));

    console.log("bura logindir", auth);
  };

  return (
    <main className="main-content  mt-0">
      <div
        className="page-header align-items-start min-vh-100"
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80 )`,
        }}
      >
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12 mx-auto">
              <div className="card z-index-0 fadeIn3 fadeInBottom">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                    <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">
                      Login
                    </h4>
                    {/* <div className="row mt-3">
                    <div className="col-2 text-center ms-auto">
                      <a className="btn btn-link px-3" href="javascript;">
                        <i className="fa fa-facebook text-white text-lg"></i>
                      </a>
                    </div>
                    <div className="col-2 text-center px-1">
                      <a className="btn btn-link px-3" href="javascript;">
                        <i className="fa fa-github text-white text-lg"></i>
                      </a>
                    </div>
                    <div className="col-2 text-center me-auto">
                      <a className="btn btn-link px-3" href="javascript;">
                        <i className="fa fa-google text-white text-lg"></i>
                      </a>
                    </div>
                  </div> */}
                  </div>
                </div>
                <div className="card-body">
                  <form className="text-start">
                    <div className="input-group input-group-outline my-3">
                      {/* <label className="form-label">Email</label> */}
                      <input
                        placeholder="email"
                        value={user.email}
                        onChange={(e) =>
                          setUser({ ...user, email: e.target.value })
                        }
                        type="email"
                        className="form-control"
                      />
                    </div>
                    <div className="input-group input-group-outline mb-3">
                      {/* <label className="form-label">Password</label> */}
                      <input
                        placeholder="password"
                        value={user.password}
                        onChange={(e) =>
                          setUser({ ...user, password: e.target.value })
                        }
                        type="password"
                        className="form-control"
                      />
                    </div>
                    {/* <div className="form-check form-switch d-flex align-items-center mb-3">
                    <input className="form-check-input" type="checkbox" id="rememberMe"/>
                    <label className="form-check-label mb-0 ms-2" for="rememberMe">Remember me</label>
                  </div> */}
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn bg-gradient-primary w-100 my-4 mb-2"
                        onClick={handleLogin}
                      >
                        Daxil ol
                      </button>

                      {auth.data.error ? (
                        <h6>Bele istifadeci yoxdur,Yenidən yoxla</h6>
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="mt-4 text-sm text-center">
                      Profiliniz yoxdur?
                      <Link
                        to="/Register"
                        className="text-primary text-gradient font-weight-bold"
                      >
                        Qeydiyyat
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer position-absolute bottom-2 py-2 w-100">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-12 col-md-6 my-auto">
                <div className="copyright text-center text-sm text-white text-lg-start">
                  <i className="fa fa-heart" aria-hidden="true"></i> Temiz xalı.
                </div>
              </div>
              <div className="col-12 col-md-6">
                <ul className="nav nav-footer justify-content-center justify-content-lg-end"></ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </main>
  );
};

export default Login;
