import React from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../state/actions/userActions";
const Table = ({ users }) => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );
  // console.log(users);
  // users && console.log(users);
  const { data } = users;
  // console.log(data);
  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(deleteUser(id));
  };

  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">
                  istifadəçilər
                </h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        userId
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Email
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Phone
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Status
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Lovely Word
                      </th>

                      <th className="text-secondary opacity-7">Tool</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.id}
                              </p>
                            </td>
                            <td>
                              <div className="d-flex px-2 py-1">
                                <div>
                                  <img
                                    src={item.image}
                                    className="avatar avatar-sm me-3 border-radius-lg"
                                    alt="user1"
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 text-sm">
                                    {item.name} {item.lastname}
                                  </h6>
                                  <p className="text-xs text-secondary mb-0">
                                    {item.email}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.phone}
                              </p>
                            </td>
                            <td className="align-middle text-center text-sm">
                              <span
                                className={` badge badge-sm bg-gradient-${
                                  item.isadmin ? "success" : "secondary"
                                } `}
                              >
                                {item.isadmin ? "admin" : "user"}
                              </span>
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item.lovelyWord}
                            </td>

                            <td className="align-middle">
                              {admin.isadmin || adminlocal ? (
                                <>
                                  {!item.isadmin && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                      >
                                        Edit
                                      </button>{" "}
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                        onClick={() => handleDelete(item.id)}
                                      >
                                        {" "}
                                        Del
                                      </button>
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
