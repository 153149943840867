import {
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_ERROR,
  CAMPAIGN_LOAD,
  CAMPAIGN_SUCCESS,
} from "../action-types/campaignActionTypes";

import axios from "axios";
// const link = `http://localhost:4000/campeign`
const link = `https://temizxali.herokuapp.com/campeign`;
export const getCampeign = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CAMPAIGN_LOAD,
      });
      const { data } = await axios.get(link);
      dispatch({
        type: CAMPAIGN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CAMPAIGN_ERROR,
        payload: error,
      });
    }
  };
};

// create campaign

export const createCampaign = (obj) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(link, obj);
      dispatch({
        type: CAMPAIGN_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {}
  };
};

export const deleteCampaign = (id) => {
  return async (dispatch) => {
    console.log("hi");
    try {
      const { data } = await axios.delete(link + "/delete/" + id);

      dispatch({
        type: CAMPAIGN_DELETE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
