import {
  CONTACT_ERROR,
  CONTACT_LOAD,
  CONTACT_SUCCESS,
  CONTACT_UPDATE,
} from "../action-types/contactActions";

const initialState = {
  loading: false,

  data: [],

  error: "",
};

export const contactReducers = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_LOAD:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CONTACT_UPDATE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
