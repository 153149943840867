

export const XIDMET_LOAD = "XIDMET_LOAD"

export const XIDMET_SUCCESS = "XIDMET_SUCCESS"

export const XIDMET_ERROR = "XIDMET_ERROR"

export const XIDMET_CREATE_SUCCESS = "XIDMET_CREATE_SUCCESS";

export const XIDMET_DELETE_SUCCESS = "XIDMET_DELETE_SUCCESS";

