import {
  MAINSERVICE_CREATE,
  MAINSERVICE_DELETE,
  MAINSERVICE_ERROR,
  MAINSERVICE_LOAD,
  MAINSERVICE_SUCCESS,
  MAINSERVICE_UPDATE,
} from "../action-types/mainserviceActions";

import axios from "axios";

// const link = `http://localhost:4000/mainservice`;
const link = `https://temizxali.herokuapp.com/mainservice`;
//localhost:4000/mainservice/delete/3

export const getMainServices = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MAINSERVICE_LOAD,
      });
      const { data } = await axios.get(link);

      dispatch({
        type: MAINSERVICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MAINSERVICE_ERROR,
        payload: error,
      });
    }
  };
};

// createMainService
export const createMainService = (obj) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(link, obj);
      dispatch({
        type: MAINSERVICE_CREATE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// delete mainservice

export const deleteMainService = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(link + "/delete/" + id);

      dispatch({
        type: MAINSERVICE_DELETE,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MAINSERVICE_ERROR,
        payload: error,
      });
    }
  };
};

export const updateMainService = (id, obj) => {
  return async (dispatch) => {
    try {
      console.log("bu action ishleyirririririiririri");

      const { data } = await axios.put(link + "/update/" + id, obj);

      dispatch({
        type: MAINSERVICE_UPDATE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
