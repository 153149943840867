import {
  ABOUT_ERROR,
  ABOUT_LOAD,
  ABOUT_SUCCESS,
  ABOUT_UPDATE,
} from "../action-types/aboutActionTypes";

const initialState = {
  load: false,
  data: [],
  error: "",
};

export const aboutReducers = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_LOAD:
      return {
        ...state,
        load: true,
      };
    case ABOUT_SUCCESS:
      return {
        ...state,
        load: false,
        data: action.payload,
      };
    case ABOUT_ERROR:
      return {
        ...state,
        load: false,
        error: action.payload,
      };
    case ABOUT_UPDATE:
      return {
        ...state,
        load: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
