

export const ORDER_LOAD = "ORDER_LOAD"

export const ORDER_SUCCESS = "ORDER_SUCCESS"

export const ORDER_ERROR = "ORDER_ERROR"

export const ORDER_DELETE = "ORDER_DELETE"

export const ORDER_UPDATE_STATUS = "ORDER_UPDATE_STATUS"
