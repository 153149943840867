

export const USER_LOAD = "USER_LOAD"

export const USER_SUCCESS = "USER_SUCCESS"

export const USER_ERROR = "USER_ERROR"

export const USER_CREATE = "USER_CREATE"

export const USER_DELETE = "USER_DELETE"