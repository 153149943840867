import {
  MAINIMAGE_CREATE,
  MAINIMAGE_DELETE,
  MAINIMAGE_ERROR,
  MAINIMAGE_LOAD,
  MAINIMAGE_SUCCESS,
  MAINIMAGE_UPDATE,
} from "../action-types/mainImageActionTypes";

import axios from "axios";

// const link = "http://localhost:4000/mainimage";
const link = "https://temizxali.herokuapp.com/mainimage";

export const getMainImage = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MAINIMAGE_LOAD,
      });

      const { data } = await axios.get(link);
      console.log(data)
      console.log("bura ishledi")

      dispatch({
        type: MAINIMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MAINIMAGE_ERROR,
        error,
      });
      console.log("error bash verdi");
    }
  };
};

// create action tested working

export const createMainImage = (dat) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(link, dat);
      dispatch({
        type: MAINIMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error");
    }
  };
};

// delete Action tested

export const deleteMainImage = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(link + "/delete/" + id);

      dispatch({
        type: MAINIMAGE_DELETE,
      });
    } catch (error) {
      console.log("error");
    }
  };
};

// update Action

export const updateMainImage = (obj, id) => {
  return async (dispatch) => {

    try {
      const { data } = await axios.put(link + "/update/" + id, obj);

      dispatch({
        type: MAINIMAGE_UPDATE,
      });
    } catch {
      console.log("xeta bash verdi");
    }
  };
};
