import {
  MAINIMAGE_DELETE,
  MAINIMAGE_ERROR,
  MAINIMAGE_LOAD,
  MAINIMAGE_SUCCESS,
  MAINIMAGE_UPDATE,
} from "../action-types/mainImageActionTypes";

const initialState = {
  data: [],
  loading: false,
  error: "",
};

export const mainImageReducers = (state = initialState, action) => {
  switch (action.type) {
    case MAINIMAGE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case MAINIMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case MAINIMAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case MAINIMAGE_DELETE:
      return {
        ...state,
        loading: false,
      };
    case MAINIMAGE_UPDATE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
