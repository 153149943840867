import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import Filebase from "react-file-base64";

import { useNavigate } from "react-router-dom";
import { createMainImage } from "../../state/actions/mainImageActions";

const MainImageCreate = () => {
  const navigate = useNavigate();
  const [xidmet, setxidmet] = useState({
    image: "",
  });

  const dispatch = useDispatch();
  const handlebutton = () => {
    dispatch(createMainImage(xidmet));
    navigate("/");
  };

  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">
                      Ana səhifə şəkili yenilə
                    </h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <span>Xidemət görsəli</span>
                      <div className="input-group input-group-outline mb-3">
                        <Filebase
                          type="file"
                          multiple={false}
                          onDone={({ base64 }) => {
                            setxidmet({ ...xidmet, image: base64 });
                          }}
                        />
                      </div>

                      <button
                        onClick={handlebutton}
                        type="button"
                        className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                      >
                        Əlavə et
                      </button>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MainImageCreate;
