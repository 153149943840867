import { combineReducers } from "redux";
import { aboutReducers } from "./aboutReducers";
import authReducers from "./authReducers";
import { campaignReducers } from "./campaignReducers";
import { contactReducers } from "./contactReducers";
import { mainImageReducers } from "./mainImageReducers";

import { mainServiceReducers } from "./mainServiceReducers";
import { orderReducers } from "./orderReducers";
import userReducers from "./userReducers";
import { videoReducers } from "./videoReducers";
import { whatsappreducers } from "./whatsappReducers";
import xidmetReducers from "./xidmetReducers";

const rootReducer = combineReducers({
  users: userReducers,
  auth: authReducers,
  xidmet: xidmetReducers,
  orders: orderReducers,
  campaigns: campaignReducers,
  videos: videoReducers,
  about: aboutReducers,
  contacts: contactReducers,
  mainService: mainServiceReducers,
  whatsapp: whatsappreducers,
  mainImage: mainImageReducers,
});

export default rootReducer;
