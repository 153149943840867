import { AUTH_ERROR, AUTH_LOGİN } from "../action-types/authActionTypes";

const initialState = {
  error: false,
  data: {},
};

const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case AUTH_LOGİN:
      if (action.payload.id) {
        localStorage.setItem("logged", JSON.stringify({ logged: true }));
        if (action.payload.isadmin) {
          localStorage.setItem("isadmin", JSON.stringify({ admin: true }));
        }
      } else {
        localStorage.setItem("logged", JSON.stringify({ logged: false }));
      }
      console.log("bura authreducers admin ici", action.payload.id);

      return {
        ...state,
        data: action.payload,
        error: false,
      };
    default:
      return state;
  }
};

export default authReducers;
