import {
  VIDEO_CREATE,
  VIDEO_DELETE,
  VIDEO_ERROR,
  VIDEO_LOAD,
  VIDEO_SUCCESS,
} from "../action-types/videoActionTypes";

import axios from "axios";

// const link = `http://localhost:4000/video`;
const link = `https://temizxali.herokuapp.com/video`;

// get video
export const getVideo = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: VIDEO_LOAD,
      });

      const { data } = await axios.get(link);
      dispatch({
        type: VIDEO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VIDEO_ERROR,
        payload: error,
      });
    }
  };
};

// create video

export const createVideo = (obj) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(link, obj);

      dispatch({
        type: VIDEO_CREATE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// delete video

export const deleteVideo = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(link + "/delete/" + id);

      dispatch({
        type: VIDEO_DELETE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
