import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCampeign } from "../../state/actions/campaignActions";

import CampaignTable from "./CampaignTable";

const Campaign = () => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );

  const data = useSelector((state) => state.campaigns);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampeign());
  }, [dispatch]);

  return (
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div class="col-md-12 text-center">
        {admin.isadmin || adminlocal ? (
          <Link
            to="/campaigncreate"
            className="btn btn-lg bg-gradient-primary btn-lg w-25 offset-24 mt-4 mb-0"
          >
            yeni kampaniya əlavə et
          </Link>
        ) : (
          ""
        )}
      </div>
      <CampaignTable campaign={data} />
    </main>
  );
};

export default Campaign;
