import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteMainService } from "../../state/actions/mainServiceActions";

const MainServiceTable = ({ data }) => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(deleteMainService(id));
  };
  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">Orders</h6>
                <p style={{ color: "white", marginLeft: 10 }}>
                  Mətnləri tam görmək üçün update buttona kliklə
                </p>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        id
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Title
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Title rus
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Text
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Text rus
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Image
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Video
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Tools
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0
                      ? data.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.id}
                                </p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.title}
                                </p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.title_ru}
                                </p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.text.slice(0, 17)}
                                </p>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.text_ru.slice(0, 17)}
                                </p>
                              </td>
                              <td>
                                <img
                                  src={`${item.image}`}
                                  alt={`${item.img}`}
                                  style={{ width: 40 }}
                                />
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.video}
                                </p>
                              </td>

                              {admin.isadmin || adminlocal ? (
                                <td className="align-middle">
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => {
                                      handleDelete(item.id);
                                    }}
                                  >
                                    Del
                                  </button>
                                  <button
                                    style={{ marginLeft: 40 }}
                                    type="button"
                                    className="btn btn-success"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() =>
                                      navigate("/mainserviceupdate", {
                                        state: item,
                                      })
                                    }
                                  >
                                    Update
                                  </button>
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainServiceTable;
