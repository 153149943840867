import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteService } from "../../state/actions/xidmetActions";

const XidmetTable = ({ xidmetler }) => {
  // console.log(users);
  // users && console.log(users);

  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );
  const navigate = useNavigate();
  const { data } = xidmetler;
  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(DeleteService(id));
  };
  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">xidmetler</h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Xidmetin adi
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Xidmetin adi rusca
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Xidmetin olcu vahidi
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        qiymet
                      </th>

                      <th className="text-secondary opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.name}
                              </p>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.name_ru}
                              </p>
                            </td>
                            <td className="align-middle text-center text-sm">
                              <span
                                className={` badge badge-sm bg-gradient-${
                                  item.isArea ? "success" : "secondary"
                                } `}
                              >
                                {item.isArea ? "kvm" : "eded"}
                              </span>
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item.price}
                            </td>

                            {admin.isadmin || adminlocal ? (
                              <td className="align-middle">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  Del
                                </button>
                                <button
                                  style={{ marginLeft: 5 }}
                                  type="button"
                                  className="btn btn-success"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={() =>
                                    navigate("/xidmetupdate", { state: item })
                                  }
                                >
                                  Update
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default XidmetTable;
