import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import Filebase from "react-file-base64";
import { createMainService } from "../../state/actions/mainServiceActions";
import { useNavigate } from "react-router-dom";

const MainServiceCreate = () => {
  const navigate = useNavigate();
  const [xidmet, setxidmet] = useState({
    title: "",
    title_ru: "",
    text: "",
    text_ru: "",
    image: "",
    video: "",
  });
  // console.log(
  //   "bura linkin uzunlugu",
  //   xidmet.text_ru.length > 1000 ||
  //     xidmet.title_ru.length > 1000 ||
  //     xidmet.title.length > 1000 ||
  //     xidmet.text.length > 1000 ||
  //     xidmet.video.lenght>1000
  // );
  const dispatch = useDispatch();
  const handlebutton = () => {
    dispatch(createMainService(xidmet));
    navigate("/");
  };

  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">Yeni xidmet elave et</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <p>Xidmetin bashliqi</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={xidmet.title}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, title: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                        {xidmet.title.length > 1000 && (
                          <h6>1000 xarakterdən artıq olmamalıdır</h6>
                        )}
                      </div>
                      <p>Xidmetin bashliqi rus</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={xidmet.title_ru}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, title_ru: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                        {xidmet.title_ru.length > 1000 && (
                          <h6>1000 xarakterdən artıq olmamalıdır</h6>
                        )}
                      </div>
                      <p>Xidmətin texti</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <textarea
                          rows={4}
                          value={xidmet.text}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, text: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                        {xidmet.text.length > 1000 && (
                          <h6>1000 xarakterdən artıq olmamalıdır</h6>
                        )}
                      </div>
                      <p>Xidmetin text rus</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <textarea
                          rows={4}
                          value={xidmet.text_ru}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, text_ru: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                        {xidmet.text_ru.length > 1000 && (
                          <h6>1000 xarakterdən artıq olmamalıdır</h6>
                        )}
                      </div>
                      <p>Xidmetin Video</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={xidmet.video}
                          onChange={(e) => {
                            setxidmet({ ...xidmet, video: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                        {xidmet.video.length > 100 && (
                          <h6>100 xarakterdən artıq olmamalıdır</h6>
                        )}
                      </div>
                      <span>Xidemət görsəli</span>
                      <div className="input-group input-group-outline mb-3">
                        <Filebase
                          type="file"
                          multiple={false}
                          onDone={({ base64 }) => {
                            setxidmet({ ...xidmet, image: base64 });
                          }}
                        />
                      </div>

                      <div className="text-center">
                        {xidmet.text_ru.length > 1000 ||
                        xidmet.title_ru.length > 1000 ||
                        xidmet.title.length > 1000 ||
                        xidmet.text.length > 1000 ||
                        xidmet.video.length > 1000 ? (
                          "daxil etdiyiniz setrlerde boshluq da nezere alinmaqla 900 xarakter istifadə edə bilərsən"
                        ) : (
                          <button
                            onClick={handlebutton}
                            type="button"
                            className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                          >
                            Əlavə et
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MainServiceCreate;
