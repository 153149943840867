import axios from "axios";
import {
  ORDER_DELETE,
  ORDER_ERROR,
  ORDER_LOAD,
  ORDER_SUCCESS,
  ORDER_UPDATE_STATUS,
} from "../action-types/orderActionTypes";

// let link = `http://localhost:4000/orders`;
const link = `https://temizxali.herokuapp.com/orders`;

export const getOrders = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ORDER_LOAD,
      });

      const { data } = await axios.get(link);

      dispatch({
        type: ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_ERROR,
        payload: "error bash verdi",
      });
    }
  };
};

export const deleteOrder = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(link + "/delete/" + id);
      dispatch({
        type: ORDER_DELETE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// update order status

export const updateOrderStatus = (id, obj) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(link + "/update/" + id, obj);

      dispatch({
        type: ORDER_UPDATE_STATUS,
        payload: data,
      });
    } catch (error) {}
  };
};
