import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMainImage } from "../../state/actions/mainImageActions";
import MainImageTable from "./MainImageTable";

const MainImage = () => {
  const { data } = useSelector((state) => state.mainImage);

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("useffect ishledi")
    dispatch(getMainImage());
  }, []);

  return (
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div className="col-md-12 text-center">
        {/* At the moment this action is not neededd if nedded open and take a chanse to see ho you can create */}
        {/* <Link
          to="/mainimagecreate"
          className="btn btn-lg bg-gradient-primary btn-lg w-25 offset-24 mt-4 mb-0"
        >
          yeni xidmət əlavə et
        </Link> */}
      </div>

      <MainImageTable data={data} />
    </main>
  );
};

export default MainImage;
