import "./App.css";
import Aside from "./components/aside/Aside.js";
import Main from "./components/main/Main";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Register from "./components/register/Register";
import Login from "./components/login/Login";

import { useSelector } from "react-redux";

import Xidmet from "./components/Xidmet/Xidmet";
import XidmetCreate from "./components/Xidmet/XidmetCreate";
import Orders from "./components/orders/Orders";
import Campaign from "./components/campaigns/Campaign";
import CampaignCreate from "./components/campaigns/CampaingCreate";
import Video from "./components/video/Video";
import VideoCreate from "./components/video/VideoCreate";
import About from "./components/about/About";
import AboutUpdate from "./components/about/AboutUpdate";
import Contact from "./components/contact/Contact";
import ContactUpdate from "./components/contact/ContactUpdate";
import MainService from "./components/mainService/MainService";
import MainServiceCreate from "./components/mainService/MainServiceCreate";
import Whatsapp from "./components/whatsapp/Whatsapp";
import WhatsappUpdate from "./components/whatsapp/WhatsappUpdate";
import XidmetUpdate from "./components/Xidmet/XidmetUpdate";
import MainServiceUpdate from "./components/mainService/MainServiceUpdate";
import MainImage from "./components/mainImage/MainImage";

import MainImageCreate from "./components/mainImage/MainImageCreate";
import MainImageUpdate from "./components/mainImage/MainImageUpdate";




const ProtectedRoute = ({ user, children }) => {
  // console.log('bura ise protec',user)

  const islogged = localStorage.getItem('logged')?JSON.parse(localStorage.getItem('logged')).logged:false
  console.log("bura protected root icerisidir", islogged)
  
  if (islogged) {
    return children;
  }

  
};

function App() {
  // const [user,setUser]=useState(false)
  const { data } = useSelector((state) => state.auth);
  // console.log('bura oradir',data)

  // useEffect(() => {
  //   setUser(true)
  // },[data])

  // console.log(data)
  // // data?.length>0&&console.log('yes')

  //  data.length>0?setUser(true):setUser(false)

  return (
    <div>
      <Router>
         
        <Aside />

     
       

        <Routes>
          {/* <Route path="/main" element={<Main />} /> */}
          <Route path="/register" element={<Register />} />
          {/* <Route path="/mainCopy" element={<MainCopy />} /> */}
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute user={data}>
                <Main />
              </ProtectedRoute>
            }
          />
          <Route
            path="/xidmetler"
            element={
              <ProtectedRoute user={data}>
                <Xidmet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/xidmetcreate"
            element={
              <ProtectedRoute user={data}>
                <XidmetCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/xidmetupdate"
            element={
              <ProtectedRoute user={data}>
                <XidmetUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute user={data}>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaigns"
            element={
              <ProtectedRoute user={data}>
                <Campaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/campaigncreate"
            element={
              <ProtectedRoute user={data}>
                <CampaignCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/video"
            element={
              <ProtectedRoute user={data}>
                <Video />
              </ProtectedRoute>
            }
          />
          <Route
            path="/videocreate"
            element={
              <ProtectedRoute user={data}>
                <VideoCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/about"
            element={
              <ProtectedRoute user={data}>
                <About />
              </ProtectedRoute>
            }
          />
          <Route
            path="/aboutupdate"
            element={
              <ProtectedRoute user={data}>
                <AboutUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoute user={data}>
                <Contact />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contactupdate"
            element={
              <ProtectedRoute user={data}>
                <ContactUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mainservice"
            element={
              <ProtectedRoute user={data}>
                <MainService />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mainserviceupdate"
            element={
              <ProtectedRoute user={data}>
                <MainServiceUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mainservicecreate"
            element={
              <ProtectedRoute user={data}>
                <MainServiceCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/whatsapp"
            element={
              <ProtectedRoute user={data}>
                <Whatsapp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/whatsappupdate"
            element={
              <ProtectedRoute user={data}>
                <WhatsappUpdate />
              </ProtectedRoute>
            }
          />

          <Route
            path="/mainimage"
            element={
              <ProtectedRoute user={data}>
                <MainImage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mainimagecreate"
            element={
              <ProtectedRoute user={data}>
                <MainImageCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mainimageupdate"
            element={
              <ProtectedRoute user={data}>
                <MainImageUpdate />
              </ProtectedRoute>
            }
          />

          <Route path="/" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
