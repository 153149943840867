import axios from "axios";

import {
  USER_CREATE,
  USER_DELETE,
  USER_ERROR,
  USER_LOAD,
  USER_SUCCESS,
} from "../action-types/userActionTypes";

// let rootlink = `http://localhost:4000/users`;
const rootlink = `https://temizxali.herokuapp.com/users`;
//localhost:4000/users/delete/33
// get all users
export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_LOAD,
      });

      const { data } = await axios.get(rootlink);

      dispatch({
        type: USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_ERROR,
        payload: error,
      });
    }
  };
};

//  create User

export const createUser = (obj) => {
  return (dispatch) => {
    try {
      const { data } = axios.post(rootlink, obj);

      dispatch({
        type: USER_CREATE,
        payload: data,
      });
    } catch (error) {
      console.log("error");
    }
  };
};

// delete user

export const deleteUser = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(rootlink + "/delete/" + id);
      dispatch({
        type: USER_DELETE,
        payload: data,
      });
    } catch (error) {
      console.log("errors");
    }
  };
};
