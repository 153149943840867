import {
  USER_ERROR,
  USER_LOAD,
  USER_SUCCESS,
  USER_CREATE,
  USER_DELETE,
} from "../action-types/userActionTypes";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOAD:
      return {
        ...state,

        loading: true,
        error: "",
      };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case USER_CREATE:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_DELETE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default userReducers;
