


export const MAINIMAGE_LOAD = "MAINIMAGE_LOAD"

export const MAINIMAGE_SUCCESS = "MAINIMAGE_SUCCESS"

export const MAINIMAGE_ERROR = "MAINIMAGE_ERROR"

export const MAINIMAGE_CREATE = "MAINIMAGE_CREATE"

export const MAINIMAGE_DELETE = "MAINIMAGE_DELETE"

export const MAINIMAGE_UPDATE = "MAINIMAGE_UPDATE"

