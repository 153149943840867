import {
  ABOUT_ERROR,
  ABOUT_LOAD,
  ABOUT_SUCCESS,
  ABOUT_UPDATE,
} from "../action-types/aboutActionTypes";
import axios from "axios";

// const link = `http://localhost:4000/about`;

const link = `https://temizxali.herokuapp.com/about`;

// get about

export const getAbout = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ABOUT_LOAD,
      });
      const { data } = await axios.get(link);
      dispatch({
        type: ABOUT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ABOUT_ERROR,
        payload: error,
      });
    }
  };
};

// update about

export const updateAbout = (id, obj) => {
  return async (dispatch) => {
    try {
      const { data } = axios.put(link + `/update/${id}`, obj);
      dispatch({
        type: ABOUT_UPDATE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
