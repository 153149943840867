import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

const Aside = () => {
  const [logged, setlogged] = useState(false);

  const auth = useSelector((state) => state.auth);

  // console.log("bura da iz admisn",auth.data.isadmin)
  useEffect(() => {
    setlogged(
      localStorage.getItem("logged")
        ? JSON.parse(localStorage.getItem("logged")).logged
        : false
    );
  }, [auth]);

  let navigate = useNavigate();
  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        ></i>
        <a className="navbar-brand m-0" href=" ">
          <img
            src="./assets/img/logo-ct.png"
            className="navbar-brand-img h-100"
            alt="main_logo"
          />
          <span className="ms-1 font-weight-bold text-white">Temiz xali</span>
        </a>
      </div>
      <hr className="horizontal light mt-0 mb-2" />
      <div
        className="collapse navbar-collapse  w-auto "
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          <li className="nav-item mt-3">
            <h6 className="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">
              İstifadəçi
            </h6>
          </li>

          {logged ? (
            <>
              {/* butun istifadeciler */}
              <li className="nav-item">
                <NavLink
                  // className="nav-link text-white  bg-gradient-primary"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">
                    Bütün istifadəçilər
                  </span>
                </NavLink>
              </li>
              {/* mainservice */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/mainservice"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Əsas xidmətlər</span>
                </NavLink>
              </li>
              {/* xidmetler */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/xidmetler"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Sadalama Xidmetler</span>
                </NavLink>
              </li>
              {/* sifarishler */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/orders"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Sifarisler</span>
                </NavLink>
              </li>
              {/* kampaniyalar */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/campaigns"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Kampaniyalar</span>
                </NavLink>
              </li>
              {/* videolar */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/video"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Videolar</span>
                </NavLink>
              </li>
              {/* haqqimizda */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/about"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Haqqımızda</span>
                </NavLink>
              </li>
              {/* elaqe */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/contact"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Əlaqə</span>
                </NavLink>
              </li>
              {/* whatsapp */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/whatsapp"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Whatsapp</span>
                </NavLink>
              </li>

              {/* mainimage */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/mainimage"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Main Image</span>
                </NavLink>
              </li>

              {/* logout */}
              <li className="nav-item">
                <button
                  className="nav-link text-white  bg-gradient-primary"
                  onClick={() => {
                    localStorage.clear();
                    navigate(`/login`);
                    window.location.reload(false);
                  }}
                >
                  LOGOUT
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white  bg-gradient-primary"
                      : "nav-link text-white"
                  }
                  to="/login"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">assignment</i>
                  </div>
                  <span className="nav-link-text ms-1">Login</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </div>
    </aside>
  );
};

export default Aside;
