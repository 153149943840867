import axios from "axios";
import {
  CONTACT_ERROR,
  CONTACT_LOAD,
  CONTACT_SUCCESS,
  CONTACT_UPDATE,
} from "../action-types/contactActions";

// const link = `http://localhost:4000/contacts`;
const link = `https://temizxali.herokuapp.com/contacts`;

//get contact
export const getContact = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONTACT_LOAD,
      });
      const { data } = await axios.get(link);

      dispatch({
        type: CONTACT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_ERROR,
        payload: error,
      });
    }
  };
};

// update constact

export const updateContact = (id, obj) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(link + "/update/" + id, obj);

      dispatch({
        type: CONTACT_UPDATE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
