import axios from "axios";
import { AUTH_ERROR, AUTH_LOGİN } from "../action-types/authActionTypes";

// const link = `http://localhost:4000/users/login`;
const link = `https://temizxali.herokuapp.com/users/login`;
// auth login action
export const login = (obj) => {
  return async (dispatch) => {
    try {
      // console.log(obj)
      const { data } = await axios.post(link, obj);
      // console.log(data)

      dispatch({
        type: AUTH_LOGİN,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
        payload: true,
      });
    }
  };
};
