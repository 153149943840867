import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAbout } from "../../state/actions/aboutActions";

import AboutTable from "./AboutTable";

const About = () => {
  const data = useSelector((state) => state.about);

  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );

  // console.log("bura authdur", admin.isadmin)
  // console.log('bura admin local',adminlocal)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);

  return (
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div class="col-md-12 text-center">
        {admin.isadmin || adminlocal ? (
          <Link
            to="/aboutupdate"
            className="btn btn-lg bg-gradient-primary btn-lg w-25 offset-24 mt-4 mb-0"
          >
            Haqqımızda hissəsini yenilə
          </Link>
        ) : (
          ""
        )}
      </div>
      <AboutTable about={data} />
    </main>
  );
};

export default About;
