import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getContact, updateContact } from "../../state/actions/contactActions";

const ContactUpdate = () => {
  const [contact, setContact] = useState({
    phone_baku: "",
    phone_baku2: "",
    phone_baku3: "",
    phone_semkir: "",
    phone_semkir2: "",
    phone_sumqayit: "",
    phone_sumqayit2: "",
    email: "",
    adress: "",
  });

  const data = useSelector((state) => state.contacts);
  const dispatch = useDispatch();
    const navigate = useNavigate();
  useEffect(() => {
    const geto = () => {
      return new Promise((resolve, reject) => {
        resolve(dispatch(getContact()));
      });
    };
    geto().then(() => setContact(data.data[0]));
  }, []);

    console.log(contact);

  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">Yenilə</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <p>Telefon 1</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.phone_baku}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              phone_baku: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Telefon  2</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.phone_baku2}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              phone_baku2: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Telefon 3</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.phone_baku3}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              phone_baku3: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Telefon 4</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.phone_semkir}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              phone_semkir: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Telefon 5</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.phone_semkir2}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              phone_semkir2: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Telefon 6</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.phone_sumqayit}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              sumqayit: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Telefon 8</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.phone_sumqayit2}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              phone_sumqayit2: e.target.value,
                            });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Email</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.email}
                          onChange={(e) => {
                            setContact({ ...contact, email: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <p>Adress</p>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label"></label>
                        <input
                          value={contact.address}
                          onChange={(e) => {
                            setContact({ ...contact, address: e.target.value });
                          }}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <div className="text-center">
                        {contact.phone_baku && contact.phone_baku2 ? (
                          <button
                            onClick={() => {
                              dispatch(updateContact(data.data[0].id, contact));
                              navigate("/");
                            }}
                            type="button"
                            className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                          >
                            Əlavə et
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactUpdate;
