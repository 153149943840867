import React, { useState } from "react";
import Filebase from "react-file-base64";
import { useDispatch } from "react-redux";
import { createUser } from "../../state/actions/userActions";
const Register = () => {
  const [user, Setuser] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
    isadmin: false,
    lovelyWord: "",
    image: "",
    phone: "",
  });

  const dispatch = useDispatch();

  // console.log(user)

  const HandleCreate = (e) => {
    e.preventDefault();
    dispatch(createUser(user));
  };

  return (
    <main className="main-content  mt-0">
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                <div
                  className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                  style={{
                    backgroundImage: `url('../assets/img/illustrations/illustration-signup.jpg')`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header">
                    <h4 className="font-weight-bolder">Qeydiyyat</h4>
                    <p className="mb-0">
                      Yeni istifadəçi məlumatlarını daxil edin
                    </p>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Ad</label>
                        <input
                          value={user.name}
                          onChange={(e) =>
                            Setuser({ ...user, name: e.target.value })
                          }
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Soyad</label>
                        <input
                          value={user.lastname}
                          onChange={(e) =>
                            Setuser({ ...user, lastname: e.target.value })
                          }
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Email</label>
                        <input
                          value={user.email}
                          onChange={(e) =>
                            Setuser({ ...user, email: e.target.value })
                          }
                          type="email"
                          className="form-control"
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Password</label>
                        <input
                          value={user.password}
                          onChange={(e) =>
                            Setuser({ ...user, password: e.target.value })
                          }
                          type="password"
                          className="form-control"
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Sevimli soz</label>
                        <input
                          value={user.lovelyWord}
                          onChange={(e) =>
                            Setuser({ ...user, lovelyWord: e.target.value })
                          }
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Phone</label>
                        <input
                          value={user.phone}
                          onChange={(e) =>
                            Setuser({ ...user, phone: e.target.value })
                          }
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <span>Profile shekili</span>
                      <div className="input-group input-group-outline mb-3">
                        <Filebase
                          type="file"
                          multiple={false}
                          onDone={({ base64 }) => {
                            Setuser({ ...user, image: base64 });
                          }}
                        />
                      </div>
                      {/* <div className="form-check form-check-info text-start ps-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          I agree the{" "}
                          <a
                            href="javascript;"
                            className="text-dark font-weight-bolder"
                          >
                            Terms and Conditions
                          </a>
                        </label>
                      </div> */}
                      <div className="text-center">
                        <button
                          onClick={HandleCreate}
                          type="button"
                          className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                        >
                          Qeydiyyat
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1">
                    <p className="mb-2 text-sm mx-auto">
                      Artiq qeydiyyat olmusuz?
                      <a
                        href="../pages/sign-in.html"
                        className="text-primary text-gradient font-weight-bold"
                      >
                        Login
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Register;
