import {
  WHATSAPP_ERROR,
  WHATSAPP_LOAD,
  WHATSAPP_SUCCESS,
  WHATSAPP_UPDATE,
} from "../action-types/whatsappActionTypes";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

export const whatsappreducers = (state = initialState, action) => {
  switch (action.type) {
    case WHATSAPP_LOAD:
      return {
        ...state,
        loading: true,
      };
    case WHATSAPP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadin: false,
        error: "",
      };
    case WHATSAPP_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case WHATSAPP_UPDATE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: "",
      };
    default:
      return state;
  }
};
