import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCampaign } from "../../state/actions/campaignActions";

const CampaignTable = ({ campaign }) => {
  const { data: admin } = useSelector((state) => state.auth);

  const [adminlocal, setAdminLocal] = useState(
    JSON.parse(localStorage.getItem("isadmin"))
  );
  const { data } = campaign;
  const dispatch = useDispatch();

  const handledelete = (id) => {
    dispatch(deleteCampaign(id));
    // console.log('hi')
  };

  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">
                  Kapmaniyalar
                </h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Kampaniya başlığı
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Kampaniya başlığı Rus
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Kampaniya image
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Kampaniya mətni
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Kampaniya mətni Rus
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Kampaniya tarix
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tool
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.error ? (
                      <>Boshdur</>
                    ) : data.length > 0 ? (
                      data.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.title}
                              </p>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.title_ru}
                              </p>
                            </td>
                            <td>
                              <div className="d-flex px-2 py-1">
                                <div>
                                  <img
                                    src={item.image}
                                    className="avatar avatar-sm me-3 border-radius-lg"
                                    alt="user1"
                                  />
                                </div>
                              </div>
                            </td>

                            <td className="align-middle text-center text-sm">
                              {item.text}
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item.text_ru}
                            </td>
                            <td className="align-middle text-center text-sm">
                              {item.day}
                            </td>

                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.userId}
                              </p>
                            </td>

                            {admin.isadmin || adminlocal ? (
                              <td className="align-middle">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={() => {
                                    handledelete(item.id);
                                  }}
                                >
                                  Del
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTable;
