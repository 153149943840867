import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../state/actions/orderActions";

import OrderTable from "./OrderTable";

const Orders = () => {
  const data = useSelector((state) => state.orders);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  return (
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <div className="col-md-12 text-center"></div>

      {/* <Table users={data} /> */}
      <OrderTable orders={data} />
    </main>
  );
};

export default Orders;
