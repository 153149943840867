import React from "react";

const ContactTable = ({ contact }) => {
  // console.log(users);
  // users && console.log(users);
  const { data } = contact;

  return (
    <div className="container-fluid py-4">
      <div
        className="huge"
        style={{
          position: "absolute",
          height: "100px",
          margin: "100%",
          backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: 99,
        }}
      ></div>
      <div className="row">
        <div className="col-12">
          <div className="card my-4">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">Əlaqə</h6>
              </div>
            </div>
            <div className="card-body px-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tel1
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tel2
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tel3
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                       Tel4
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tel5
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tel6
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tel7
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Email
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Unvan
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.error ? (
                      <>Boshdur</>
                    ) : data?.length > 0 ? (
                      data.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.phone_baku}
                              </p>
                            </td>

                            <td className="text-xs font-weight-bold mb-0">
                              {item.phone_baku2}
                            </td>
                            <td className="text-xs font-weight-bold mb-0">
                              {item.phone_baku3}
                            </td>
                            <td className="text-xs font-weight-bold mb-0">
                              {item.phone_semkir}
                            </td>
                            <td className="text-xs font-weight-bold mb-0">
                              {item.phone_semkir2}
                            </td>
                            <td className="text-xs font-weight-bold mb-0">
                              {item.phone_sumqayit}
                            </td>
                            <td className="text-xs font-weight-bold mb-0">
                              {item.phone_sumqayit2}
                            </td>
                            <td className="text-xs font-weight-bold mb-0">
                              {item.email}
                            </td>
                            <td className="text-xs font-weight-bold mb-0">
                              {item.address}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactTable;
